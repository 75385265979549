import React from "react";
import resumeFile from "../documents/Dustin-Brown-Resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2000 - 2003",
      title: "Computer Science",
      place: "The University of Kansas",
      desc: "Graduated in 3 years while working part time as a developer at the University.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2022 - Current",
      title: "Director of Software Development",
      place: "Ellucian",
      desc: "Lead a team of 70 managers, developers, and analysts across India, Ireland, Mexico, and the United States. ~$850M in ARR",
    },
    {
      yearRange: "2019-2022",
      title: "Head of Software Development",
      place: "Identity Automation",
      desc: "Oversaw the SaaSification of K-12 Identity and Access Management product generating $20+M in ARR",
    },
    {
      yearRange: "2017-2019",
      title: "Senior Software Development Manager",
      place: "Identity Automation",
      desc: "Returned to Identity Automation after a private equity infusion to help lead the next growth phase of the company.",
    },
    {
      yearRange: "2015-2017",
      title: "Founder & CTO",
      place: "Prime Edits",
      desc: "Founded a cloud based photo and video editing service for photographers around the world.",
    },
    {
      yearRange: "2009-2015",
      title: "Founding Software Architect",
      place: "Identity Automation",
      desc: "Founded the software practice at Identity Automation expanding on the already successful K-12 EdTech consulting business.",
    },
    {
      yearRange: "2003-2009",
      title: "Software Developer / Software Development Manager",
      place: "The University of Kansas",
      desc: "Led a group of 7 engineers responsible for the core Identity and Access Management services on campus.",
    },
  ];

  const skills = [
    {
      name: "Leadership",
      percent: 100,
    },
    {
      name: "Strategic Hiring",
      percent: 95,
    },
    {
      name: "Amazon Web Services (AWS)",
      percent: 80,
    },
    {
      name: "React Native",
      percent: 70,
    },
    {
      name: "Java",
      percent: 80,
    },
    {
      name: "Agile Methodologies",
      percent: 100,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download Resume
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
