import React from "react";
import Slider from "react-slick";

const Testimonials = ({ classicHeader, darkTheme }) => {
  const reviews = [
    {
      name: "Kathryn H.",
      position: "Senior Software Developer",
      src: "images/testimonial/7751467.jpeg",
      desc: "Dustin is a proficient and forward-thinking leader with excellent management skills, as he took a chance on me as a junior developer. Under his guidance, I was entrusted with challenging projects that significantly contributed to my growth as a software developer. This opportunity to work on difficult tasks not only expanded my skills, but also showcased Dustin's expertise in nurturing talent within the team. I am genuinely grateful for the trust he placed in me and the opportunity to shoulder significant responsibilities at the start of my career.",
      rating: 5,
    },
    // {
    //   name: "Kevin B.",
    //   position: "Lead Software Developer",
    //   src: "images/testimonial/f9c9H.png",
    //   desc: "Easy to use, reasonably priced simply dummy text of the printing and typesetting industry. Quidam lisque persius interesset his et, in quot quidam possim iriure.",
    //   rating: 5,
    // },
    {
      name: "Jeff P.",
      position: "Director, Cloud Transformation",
      src: "images/testimonial/1600621499869.jpg",
      desc: "Dustin is an extremely competent developer and manager. He is very flexible and is willing to change a project midstream to better achieve a business objective. I see Dustin as a leader in cutting edge development and design. His team consistently produces work that exceeds our objective and design requirements. Each project with Dustin is a breath of fresh air.",
      rating: 5,
    },
    // {
    //   name: "Chris Tom",
    //   position: "User from UK",
    //   src: "images/testimonial/client-sm-4.jpg",
    //   desc: "I have used them twice now. Good rates, very efficient service and priced simply dummy text of the printing and typesetting industry quidam interesset his et. Excellent.",
    //   rating: 5,
    // },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonial"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Testimonial
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Recommendations
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid rounded-circle border d-inline-block w-auto"
                      src={value.src}
                      alt=""
                    />
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {" "}
                        {value.position}{" "}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      " fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                    }
                  >
                    “{value.desc}”
                  </p>
                  {/* <span className="text-2">
                    {[...Array(value.rating)].map((value, i) => (
                      <i className="fas fa-star text-warning" key={i} />
                    ))}
                  </span> */}
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
